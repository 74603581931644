import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { matchingPasswordsValidator } from 'src/app/utils/password-match.validator';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-register-auth',
  templateUrl: './register-auth.component.html',
  styleUrls: ['./register-auth.component.scss'],
})
export class RegisterAuthComponent implements OnInit {
  hide = true;
  hideConfirmPassword = true;
  formRegister: FormGroup;
  hasError: boolean = false;
  savingData: boolean = false;
  isSave: boolean = false;

  @Output() formValid = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.formRegister = this.fb.group(
      {
        mail: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      },
      { validators: matchingPasswordsValidator('password', 'confirmPassword') } // Aqui aplica a validação personalizada
    );
  }

  login() {
    this.savingData = true;
    const login = {
      userName: this.formRegister.value.mail,
      password: this.formRegister.value.password,
    };
    this.sharedService.login(login).subscribe({
      next: (res) => {
        this.savingData = false;
        this.isSave = true;
        this.formValid.emit(true);
      },
      error: () => {
        this.hasError = true;

        this.savingData = false;
      },
    });
  }

  ngOnInit(): void {}
}
