import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  CurrencyMaskConfig,
  CurrencyMaskModule,
  CURRENCY_MASK_CONFIG,
} from 'ng2-currency-mask';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt, 'pt');

import { AngularMaterialModule } from './angular-material.module';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneFormatterPipe } from '../utils/phone-formatter.pipe';
import { BlankslateComponent } from '../components/blankslate/blankslate.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectBankComponent } from '../components/select-search/select-bank/select-bank.component';
import { RegisterAuthComponent } from '../shared/register-auth/register-auth.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
};

@NgModule({
  declarations: [
    PhoneFormatterPipe,
    BlankslateComponent,
    SelectBankComponent,
    RegisterAuthComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    CurrencyMaskModule,
    NgxMatSelectSearchModule,
  ],
  exports: [
    FormsModule,
    CommonModule,
    AngularMaterialModule,
    NgxMaskModule,
    ReactiveFormsModule,
    PhoneFormatterPipe,
    BlankslateComponent,
    CurrencyMaskModule,
    NgxMatSelectSearchModule,
    SelectBankComponent,
    RegisterAuthComponent,
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  ],
})
export class SharedModule {}
