import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInterface } from '../interfaces/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private httpClient: HttpClient) {}

  login(data: AuthInterface) {
    const companyId = sessionStorage.getItem('user_id')?.split('"')[1];

    const url = environment.url + `/open/register/${companyId}/signup`;
    return this.httpClient.post<AuthInterface>(url, data);
  }
}
