import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function matchingPasswordsValidator(
  passwordKey: string,
  confirmPasswordKey: string
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const password = formGroup.get(passwordKey)?.value;
    const confirmPassword = formGroup.get(confirmPasswordKey)?.value;

    if (password !== confirmPassword) {
      formGroup.get(confirmPasswordKey)?.setErrors({ passwordsMismatch: true });
      return { passwordsMismatch: true };
    } else {
      formGroup.get(confirmPasswordKey)?.setErrors(null);
      return null;
    }
  };
}
